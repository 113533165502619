import { FormControl, InputLabel, MenuItem, Select} from "@mui/material";

// @ts-ignore
const Settings = ({minerValue, minerChangeHandler, transformerValue, transformerChangeHandler, modularReactorValue, modularReactorChangeHandler, microprocessorValue, microprocessorChangeHandler}) => {

    return(
        <div>
            <FormControl>
                <InputLabel id="input-transformer-label">Transformer build in</InputLabel>
                <Select label="Transformer build in" labelId="input-transformer-label" style={{minWidth: "300px"}}
                        onChange={transformerChangeHandler}
                        value={transformerValue}>
                    <MenuItem value = "c_human_factory_robots">Human Factory</MenuItem>
                    <MenuItem value = "c_assembler">Assembler</MenuItem>
                </Select>
                </FormControl>
                    <FormControl>
                <InputLabel id="input-reacor-label">Small Modular Reactor build in</InputLabel>
                <Select label="Small Modular Reactor build in" labelId="input-reactor-label" style={{minWidth: "300px"}}
                        onChange={modularReactorChangeHandler}
                        value={modularReactorValue}>
                    <MenuItem value = "c_human_factory_robots">Human Factory</MenuItem>
                    <MenuItem   value = "c_assembler">Assembler</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="input-microprocessor-label">Microprocessor build in</InputLabel>
                <Select label="Microprocessor build in" labelId="input-microprocessor-label" style={{minWidth: "300px"}}
                        onChange={microprocessorChangeHandler}
                        value={microprocessorValue}>
                    <MenuItem value = "c_human_factory_robots">Human Factory</MenuItem>
                    <MenuItem value="c_robotics_factory">Robotic Factory</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="input-miner-label">Preferred Miner</InputLabel>
                <Select label="Preferred Miner" labelId="input-miner-label" style={{minWidth: "300px"}}
                        onChange={minerChangeHandler}
                        value={minerValue}>
                    <MenuItem value = "c_miner">Miner</MenuItem>
                    <MenuItem value = "c_adv_miner">Laser Mining Tool</MenuItem>
                </Select>
            </FormControl>
        </div>
    )

}

export default Settings