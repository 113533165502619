import {Box, Button, SelectChangeEvent} from "@mui/material";
import classes from "./RecipeSelection.module.css"
import RecipeRow from "../components/RecipeRow";
import {useState} from "react";
import {getItem, getTicksPerItem} from "../utils/loadItems";

// @ts-ignore
const RecipeSelection = ({ preferredMiner, productionHandler, transformerBuild, modularReactorBuild, microProcessorBuild}) => {

    const [recipes, setRecipes] = useState(new Map())
    const [lastId, setLastId] = useState(-1)


    const onRecipeChange = (event: SelectChangeEvent, id:number) => {

        const identifier = event.target.value
        const item = recipes.get(id);
        item.identifier = identifier

        let ticksPerItem = 0

        if("transformer" === identifier){
            ticksPerItem = getTicksPerItem(identifier, transformerBuild)
        }else if("smallreactor" === identifier){
            ticksPerItem = getTicksPerItem(identifier, modularReactorBuild)

        }else if("micropro" === identifier){
            ticksPerItem = getTicksPerItem(identifier, microProcessorBuild)
        }else {

            ticksPerItem = getTicksPerItem(identifier, preferredMiner)
        }
        item.ticksPerItem = ticksPerItem

        setRecipes(map => new Map(map.set(id, item)));

        productionHandler(Array.from(recipes.entries()).map((entry) =>{
            const [, value] = entry;
            return value;
        }))

    }

    const onDeleteRow = (id:number) => {

        const tmp = new Map(recipes);
        tmp.delete(id)
        setRecipes(tmp)

        productionHandler(Array.from(tmp.entries()).map((entry) =>{
            const [, value] = entry;
            return value;
        }))
    }
    const onAmountChange = (event: InputEvent, id:number) => {

        const item = recipes.get(id);
        // @ts-ignore
        item.amount = event.target.value
        // @ts-ignore
        item.itemsPerMin = (300/ item.ticksPerItem) * Number(event.target.value)

        setRecipes(map => new Map(map.set(id, item)));



        productionHandler(Array.from(recipes.entries()).map((entry) =>{
            const [, value] = entry;
            return value;
        }))
    }

    const onItemsPerMinChange = (event: InputEvent, id:number) => {


        const item = recipes.get(id);
        // @ts-ignore
        item.itemsPerMin = Number(event.target.value)
        // @ts-ignore
        item.amount = (Number(event.target.value)* item.ticksPerItem) / 300

        productionHandler(Array.from(recipes.entries()).map((entry) =>{
            const [, value] = entry;
            return value;
        }))
    }

    const addRowHandler = () => {

        const id = lastId+1;
        const item = {identifier: "metalbar", amount: "1", itemsPerMin: 300/20, ticksPerItem: 20};

        setRecipes(map => new Map(map.set(id, item)));
        setLastId(id)

        productionHandler(Array.from(recipes.entries()).map((entry) =>{
            const [, value] = entry;
            return value;
        }))
    }




    return(
        <div className={classes.RecipeSelection}>

            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                marginLeft: '20px'}}>
                {

                    Array.from(recipes.entries()).map((entry) =>{
                        const [key, value] = entry;
                        return <RecipeRow
                            deleteHandler={() => onDeleteRow(key)}
                            key={key}
                            selectedRecipe={value.identifier}
                            selectedAmount={value.amount}
                            itemsPerMin={value.itemsPerMin}
                            itemsPerMinHandler={(event: InputEvent) => onItemsPerMinChange(event, key)}
                            recipeChangeHandler={(event: SelectChangeEvent<string>) => onRecipeChange(event, key)}
                            amountChangeHandler={(event: InputEvent) => onAmountChange(event, key)}/>;
                    })
                }
              <Button onClick={addRowHandler}>+</Button>
            </Box>
        </div>
    )
}

export default RecipeSelection;