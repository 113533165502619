import items from '../assets/desynced_items.json';

const miners = ["c_miner","c_adv_miner", "c_extractor"];
const productionBuildings = ["c_fabricator","c_human_science_analyzer_robots", "c_refinery","c_assembler", "c_human_factory_robots","c_virus_decomposer", "c_robotics_factory", "c_alien_factory", "c_data_analyzer"];


// @ts-ignore
const data: any[] = []

export const getAllItems = () => {
    if(data.length > 0){
        return data;
    }

    for(const [key, value] of Object.entries(items.data)){
        data.push({...value, identifier: key})
    }

    return data

}

export const getItem = (name :string) => {

    // @ts-ignore
    return(items.data[name])

}

export const isBaseResource = (identifier:string) => {
    // @ts-ignore
    if(items.data[identifier] === undefined){
        console.log(identifier)
    }
    // @ts-ignore
    return items.data[identifier].production_recipe === undefined;

}

export const getSubRecipeIngredients = (identifier:string, inputItemsPerTick:number) =>{

    let allIngredients:Map<string, number> = new Map()

    allIngredients.set(identifier, inputItemsPerTick);

    const recipe = getItem(identifier).production_recipe

    if( recipe === undefined){
        return allIngredients;
    }

    Object.keys(recipe).forEach(key => {
        if(productionBuildings.includes(key)){
            return
        }
        allIngredients =mergeIngredientMaps(getSubRecipeIngredients(key, recipe[key]*inputItemsPerTick), allIngredients);
    })

    return allIngredients
}


export const mergeIngredientMaps = ( mapA:Map<string, number>, mapB:Map<string, number>) => {


    if(mapA === undefined && mapB === undefined){
        return new Map<string, number>()
    }

    if(mapB === undefined){
        return mapA
    }
    if(mapA === undefined){
        return mapB
    }

    const result: Map<string, number> = new Map()

    mapA.forEach((value, key) => {

        if(mapB.has(key)){
            const valueB = mapB.get(key);
            if(valueB !== undefined) {
                result.set(key, value + valueB)
            }
        }else {
            result.set(key, value);
        }
    })

    mapB.forEach((value, key) => {
        if(result.has(key)){
            return
        }
        if(mapA.has(key)){
            const valueA = mapA.get(key);
            if(valueA !== undefined) {
                result.set(key, value + valueA)
            }
        }else {
            result.set(key, value);
        }
    })

    return result
}

export const getTicksPerItem =( identifier:string, perferedBuild:string) =>{



    let foundKey = ""
    let data:any = null;

    let foundPreferred = false;

    if(getItem(identifier).production_recipe !== undefined){
        data = getItem(identifier).production_recipe;
        Object.keys(data).forEach(key => {
            if(productionBuildings.includes(key) && !foundPreferred){
                foundKey = key
                if(key === perferedBuild){
                    foundPreferred = true
                }
            }
        })
    }

    if(getItem(identifier).mining_recipe !== undefined){
        data = getItem(identifier).mining_recipe;

        Object.keys(data).forEach(key => {
            if(miners.includes(key) && !foundPreferred){
                foundKey = key
                if(key === perferedBuild){
                    foundPreferred = true
                }
            }
        })
    }
    if(foundKey !== ""){
        return data[foundKey]
    }
    return
}