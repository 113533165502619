import {getItem, getTicksPerItem} from "../utils/loadItems";
import {useEffect, useState} from "react";

// @ts-ignore
const ResultRow = ({ itemIdentifier, itemsPerTick, preferredMiner, transformerBuild, microProcessorBuild, modularReactorBuild}) => {

    const [ticksPerItem, setTicksPerItem] = useState(0);




    useEffect(() => {


        if("transformer" === itemIdentifier){
            setTicksPerItem(getTicksPerItem(itemIdentifier, transformerBuild))
        }else if("smallreactor" === itemIdentifier){
            setTicksPerItem(getTicksPerItem(itemIdentifier, modularReactorBuild))

        }else if("micropro" === itemIdentifier){
            setTicksPerItem(getTicksPerItem(itemIdentifier, microProcessorBuild))
        }else {

            setTicksPerItem(getTicksPerItem(itemIdentifier, preferredMiner))
        }


    }, [itemIdentifier, microProcessorBuild, modularReactorBuild, preferredMiner, transformerBuild])

    return(
        <div>
            {getItem(itemIdentifier).name} -- {Math.round(ticksPerItem * itemsPerTick *100)/100}
        </div>
    )
}

export default ResultRow