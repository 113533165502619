import RecipeSelection from "./RecipeSelection";
import {Box, Divider} from "@mui/material";
import NeededProduction from "./NeededProduction";
import React, {useState} from "react";
import classes from "./DesyncedCalculator.module.css"
import Settings from "./Settings";




const DesyncedCalculator = () => {


    const [allProduction, setAllProduction ] = useState<any[]|[]>([]);
    const [transformerBuild, setTransformerBuild] = useState("c_human_factory_robots");
    const [microprocessorBuild, setMicroprocessorBuild] = useState("c_human_factory_robots");
    const [modularReactorBuild, setModularReactorBuild] = useState("c_human_factory_robots");
    const [preferredMiner, setPreferredMiner] = useState("c_adv_miner");


    return(
        <div className={classes.DesyncedCalculator}>

                <div>
                    <Settings
                        transformerChangeHandler={(event:any) => setTransformerBuild(event.target.value)}
                        microprocessorChangeHandler={(event:any) => setMicroprocessorBuild(event.target.value)}
                        modularReactorChangeHandler={(event:any) => setModularReactorBuild(event.target.value)}
                        microprocessorValue={microprocessorBuild}
                        minerValue={preferredMiner}
                        minerChangeHandler={(event:any) => setPreferredMiner(event.target.value)}
                        modularReactorValue={modularReactorBuild}
                        transformerValue={transformerBuild}
                    />
                    <Divider/>
                    <RecipeSelection
                        transformerBuild={transformerBuild}
                        microProcessorBuild={microprocessorBuild}
                        modularReactorBuild={modularReactorBuild}
                        preferredMiner = {preferredMiner}
                        productionHandler = {setAllProduction}/>
                    <Divider/>
                    <NeededProduction
                        transformerBuild={transformerBuild}
                        microProcessorBuild={microprocessorBuild}
                        modularReactorBuild={modularReactorBuild}
                        preferredMiner = {preferredMiner}
                        production={allProduction}/>
                </div>
        </div>)
}

export default DesyncedCalculator