import React from 'react';
import './App.css';
import {createTheme, CssBaseline, Divider, ThemeProvider} from "@mui/material";
import RecipeSelection from "./containers/RecipeSelection";
import NeededProduction from "./containers/NeededProduction";
import DesyncedCalculator from "./containers/DesyncedCalculator";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function App() {
  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline/>
        <div className="App">
            <DesyncedCalculator/>
        </div>
      </ThemeProvider>
  );
}

export default App;
