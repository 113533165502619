import {useEffect, useState} from "react";
import {getItem, getSubRecipeIngredients, mergeIngredientMaps} from "../utils/loadItems";
import RecipeRow from "../components/RecipeRow";
import {SelectChangeEvent} from "@mui/material";
import ResultRow from "../components/ResultRow";

// @ts-ignore
const NeededProduction = ({production, preferredMiner, transformerBuild, microProcessorBuild, modularReactorBuild}) => {


    const [totalNeeded, setTotalNeeded] = useState(new Map<string, number>())

    useEffect(() => {

        const productionMap = new Map<string, number>();
        let totalAmount  =  new Map<string, number>();


        production.forEach((item:any) => {


            let itemsPerTick = productionMap.get(item.identifier)

            if(itemsPerTick === undefined){
                itemsPerTick = Number(item.itemsPerMin/300)
            }
            else{
                itemsPerTick += Number(item.itemsPerMin/300)
            }
            productionMap.set(item.identifier, itemsPerTick)

        })


        productionMap.forEach((value, key) => {
            totalAmount = mergeIngredientMaps(totalAmount, getSubRecipeIngredients(key, value))

        })



        setTotalNeeded(totalAmount)


    }, [production])




    return <div>
        {Array.from(totalNeeded.entries()).map((entry) => {
            const [key, value] = entry;
            return <ResultRow

                preferredMiner={preferredMiner}
                transformerBuild={transformerBuild}
                microProcessorBuild={microProcessorBuild}
                modularReactorBuild={modularReactorBuild}
                itemIdentifier ={key} itemsPerTick = {value}/>;
        })}
    </div>
}
export default NeededProduction