import {getAllItems} from "../utils/loadItems";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";

// @ts-ignore
const RecipeRow = ({recipeChangeHandler, selectedRecipe, selectedAmount, amountChangeHandler, deleteHandler, itemsPerMinHandler, itemsPerMin}) => {

    const d = getAllItems();



    return(
            <FormControl>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        p: 1,
                        m: 1,
                        height: 100,
                        borderRadius: 1,
                    }}
                >
                    <InputLabel id="input-select-label">Recipe</InputLabel>
                <Select label="Recipe" labelId="input-select-label" style={{minWidth: "200px"}}
                value={selectedRecipe}
                        onChange={recipeChangeHandler}
                >


                    {
                        d.map((value, index) => {

                            if(value.production_recipe !== undefined || value.mining_recipe !== undefined){
                                return <MenuItem key={index} value={value.identifier}>{value.name}</MenuItem>
                            }
                            return null;
                        })
                    }
                </Select>
                <TextField label="Number of factories" type="Number" value={selectedAmount} onChange={amountChangeHandler}></TextField>

                    <TextField label="Items per Minute" type="Number" value={itemsPerMin} onChange={itemsPerMinHandler}></TextField>
                    <Button onClick={deleteHandler}>-</Button>
                </Box>
            </FormControl>

    )
}

export default RecipeRow;